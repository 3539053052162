import React, { ReactElement } from 'react';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Pricing } from '../components/index/Pricing';
import { Marketers } from '../components/marketers/Marketers';
import { Meta } from '../components/Meta';

interface Props { }

function Features(_props: Props): ReactElement {
  return (
    <>
      <Meta title="Hỗ trợ toàn diện cho hoạt động marketing bất động sản | cokhach.vn"
        description="Tất cả tính năng bạn cần trong một công cụ. Marketing tự động hoá, tiết kiệm chi phí, thời gian & nguồn lực. | Bắt đầu sử dụng miễn phí!" />
      <Header isBgTransparent={true} />
      <Marketers />
      <Pricing />
      <Footer />
    </>
  )
}

export default Features
