import { Link } from "gatsby"
import React from "react"

import { REACT_APP_SIGNUP_URL } from "../../constants"
import { CTA2 } from "../index/CTA"
import { ImageLeft, ImageRight } from "../parts/blocks"
import { SectionTitle } from "../parts/Title"

export function Values() {
  return (
    <>
      <SaveCost />
      <CTA2 title="Trọn bộ công cụ để PRO marketing" />
      <Automate />
    </>
  )
}

function SaveCost() {
  const content = (
    <>
      <SectionTitle>
        Hỗ trợ toàn diện cho hoạt động marketing bất động sản
      </SectionTitle>
      <p className="mb-8 leading-relaxed text-gray-normal text-left">
        Tất cả tính năng trong một công cụ, thiết lập đơn giản, tiết kiệm chi
        phí tối đa.
        <ul className="list-none list-inside pt-8 w-full text-left text-blue-night">
          <li>• Trình tạo landing page sử dụng thao tác kéo thả.</li>
          <li>• Web hosting MIỄN PHÍ.</li>
          <li>• Ghi hình phiên truy cập của khách thăm tiềm năng.</li>
          <li>• Báo cáo phân tích quảng cáo chuyên sâu.</li>
        </ul>
      </p>
    </>
  )
  const image = (
    <img
      className="object-cover object-center rounded"
      alt="hero"
      src="https://cdn.cokhach.vn/pimage/home-page/mkt-image-right.png"
    />
  )
  return <ImageRight {...{ content, image }} />
}

function Automate() {
  const content = (
    <>
      <SectionTitle>Marketing tự động hoá, tiết kiệm thời gian</SectionTitle>
      <p className="mb-8 leading-relaxed text-gray-normal text-left">
        Tự động hoá luồng công việc tối đa có thể, giúp bạn tập trung vào những
        tác vụ đòi hỏi tính sáng tạo.
        <ul className="list-none list-inside pt-8 w-full text-left text-blue-night">
          <li>• Marketing tự động hoá: email newsletter, chatbot…</li>
          <li>• Tự động phát hiện và chặn IP ảo.</li>
          <li>
            • Tự động hoá một số thiết lập quảng cáo Google Ads, Facebook Ads.
          </li>
        </ul>
      </p>
      <Link
        id="marketers_singup"
        className="md:mt-0 mt-8 md:w-auto w-full font-medium text-white bg-pink-medium border-0 py-md px-5 focus:outline-none hover:bg-pink-500 rounded-md text-base"
        to={`${REACT_APP_SIGNUP_URL}`}
        target="_blank"
      >
        Dùng thử miễn phí
      </Link>
    </>
  )
  const image = (
    <img
      className="object-cover object-center rounded"
      alt="hero"
      src="https://cdn.cokhach.vn/pimage/home-page/mkt-image-left.png"
    />
  )
  return <ImageLeft {...{ content, image }} />
}
