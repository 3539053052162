import React from "react"
import { PageTitle } from "../parts/Title"
import { Values } from "./Values"

export function Marketers() {
  return (
    <section className="text-gray-600 font-body overflow-hidden" id="marketer">
      <div className="pt-14 mx-auto md:bg-top-pattern bg-no-repeat bg-contain bg-none">
        <div className="py-8 pb-5 md:bg-none bg-top-pattern bg-no-repeat bg-cover bg-center">
          <PageTitle>Tăng tốc hoạt động marketing của bạn</PageTitle>
        </div>
        <Values />
      </div>
    </section>
  )
}
