import React from "react"

type Props = {
  content: React.ReactNode
  image: React.ReactNode
}

export const ImageRight: React.FC<Props> = (props) => {
  return (
    <section className="text-blue-night font-body bg-white">
      <div className="container mx-auto flex px-4 md:py-20 py-8 md:flex-row flex-col items-center">
        <div className="md:w-4/12 w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-6 md:mb-0 items-center text-center">
          {props.content}
        </div>
        <div className="md:w-8/12 w-full">{props.image}</div>
      </div>
    </section>
  )
}

export const ImageLeft: React.FC<Props> = (props) => {
  return (
    <section className="text-blue-night font-body bg-white">
      <div className="container mx-auto flex px-4 md:py-20 py-8 md:flex-row-reverse flex-col items-center ">
        <div className="md:w-4/12 w-full lg:pr-24 flex flex-col md:items-start md:text-left mb-6 md:mb-0 items-center text-center">
          {props.content}
        </div>
        <div className="md:w-8/12 w-full">{props.image}</div>
      </div>
    </section>
  )
}
